import AddVehiclePaths from 'modules/irp/modules/supplements/modules/add_vehicle/routes/paths';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import SupplementSteps from 'modules/irp/modules/supplements/components/SupplementSteps';

export default function AddVehicleSteps() {
	const { t } = useTranslation(['irp/supplements/add_vehicle', 'irp/supplements']);

	const { supplementKey } = useTypedParams(AddVehiclePaths);

	const steps = [
		{ label: t('details.step'), route: AddVehiclePaths.Details.buildPath({ supplementKey }) },
		{
			label: t('documentation.step', { ns: 'irp/supplements' }),
			route: AddVehiclePaths.Documentation.buildPath({ supplementKey }),
		},
		{ label: t('verify.step', { ns: 'irp/supplements' }), route: AddVehiclePaths.Verify.buildPath({ supplementKey }) },
		{ label: t('submit.step', { ns: 'irp/supplements' }), route: AddVehiclePaths.Submit.buildPath({ supplementKey }) },
	];

	return <SupplementSteps steps={steps} />;
}
